<template>
  <div>
    <v-row>
      <v-col>
        <h2 class="align-self-end page-title">
          {{ $i18n.t("statistic.title.application") }}
        </h2>
      </v-col>
    </v-row>
    <FilterEmployeeDash :isStatuses="true" @download="downloadStat" />
    <v-row class="row-border-bottom">
      <v-col v-if="!emptyCompanyChart">
        <highcharts v-if="showDash" :options="chartOptions" />
        <div v-else class="progress-wrap">
          <v-progress-circular
            :size="50"
            color="privat"
            indeterminate
          ></v-progress-circular>
        </div>
      </v-col>
      <v-col
        v-else
        class="d-flex flex-column justify-center align-center my-7 no-data"
      >
        <v-icon x-large>mdi-alert-circle-outline</v-icon>
        <span class="text-subtitle-1 pt-1 font-weight-bold">
          {{ $i18n.t("statistic.emptyData") }}
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h2 class="align-self-end page-title">
          {{ $i18n.t("statistic.title.applicationCreating") }}
        </h2>
      </v-col>
    </v-row>
    <FilterEmployeeDash :isSalon="true" @download="downloadStatQuantity" />
    <v-row class="row-border-bottom">
      <v-col>
        <v-data-table
          :headers="tableQuantityHeader"
          :items="tableQuantityData"
          :loading="loading"
          :mobile-breakpoint="600"
          item-key="name"
          hide-default-footer
          locale="uk"
          class="elevation-1"
        >
          <v-progress-linear
            v-show="loading"
            color="privat"
            indeterminate
          ></v-progress-linear>
          <template v-slot:loading>
            <div class="d-flex flex-column justify-center align-center">
              <span class="text-subtitle-1 pt-7 pb-5">
                {{ $t("load") }}
              </span>
            </div>
          </template>
          <template v-slot:no-data>
            <div class="d-flex flex-column justify-center align-center my-7">
              <v-icon x-large>mdi-alert-circle-outline</v-icon>
              <span class="text-subtitle-1 pt-1 font-weight-bold">
                {{ $i18n.t("statistic.emptyData") }}
              </span>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h2 class="align-self-end page-title">
          {{ $i18n.t("statistic.title.bySum") }}
        </h2>
      </v-col>
    </v-row>
    <FilterEmployeeDash :isSalon="true" @download="downloadBySum" />
    <v-row class="row-border-bottom">
      <v-col v-if="!emptySumChart">
        <highcharts v-if="showDashSum" :options="chartOptionsSum" />
        <div v-else class="progress-wrap">
          <v-progress-circular
            :size="50"
            color="privat"
            indeterminate
          ></v-progress-circular>
        </div>
      </v-col>
      <v-col
        v-else
        class="d-flex flex-column justify-center align-center my-7 no-data"
      >
        <v-icon x-large>mdi-alert-circle-outline</v-icon>
        <span class="text-subtitle-1 pt-1 font-weight-bold">
          {{ $i18n.t("statistic.emptyData") }}
        </span>
      </v-col>
    </v-row>
    <base-snackbar :props="snack" :show="show" @close="show = false" />
  </div>
</template>

<script>
import FilterEmployeeDash from "@/components/Statistic/FilterEmployeeDash";
import {
  getEmployeeQuantitySumStats,
  getEmployeeQuantityStats,
  getPBEmployeeQuantityStats,
  getPBEmployeeSumStats
} from "@/services/statistics.service";
import errorHandler from "@/utils/errorHandler";
import showSnack from "@/mixins/showSnack.mixins";
import checkFormDate from "@/utils/checkFormDate";
import { mapGetters } from "vuex";
import { createTableQuantity } from "@/utils/createStatsTables";

export default {
  name: "EmloyeeStat",
  components: {
    FilterEmployeeDash
  },
  mixins: [showSnack],
  data: () => ({
    loading: true,
    form: null,
    formEmployees: null,
    formSum: null,
    xAxis: [],
    xAxisSum: [],
    series: null,
    seriesSum: null,
    showDash: false,
    tableQuantityData: [],
    tableQuantityHeader: [],
    emptyCompanyChart: false,
    showDashSum: false,
    emptySumChart: false
  }),
  computed: {
    ...mapGetters(["getStatus", "getOrdersAndSettingsOptions"]),
    chartOptions() {
      return {
        chart: {
          type: "xy"
        },
        title: {
          text: ``
        },
        xAxis: {
          categories: this.xAxis,
          labels: {
            enabled: true,
            rotation: 300
          }
        },
        yAxis: {
          title: {
            text: ""
          },
          min: 0,
          max: this.yAxisMax,
          allowDecimals: false
        },
        tooltip: {
          shared: true,
          useHTML: true
        },
        plotOptions: {
          column: {
            stacking: "normal"
          }
        },
        series: this.series
      };
    },
    chartOptionsSum() {
      return {
        chart: {
          type: "bar"
        },
        title: {
          text: ""
        },
        xAxis: {
          categories: this.xAxisSum
        },
        yAxis: {
          min: 0,
          title: {
            text: ""
          }
        },
        legend: {
          reversed: true
        },
        plotOptions: {
          series: {
            stacking: "normal"
          }
        },
        series: this.seriesSum
      };
    }
  },
  created() {
    this.getEsaDefaultSettings();
  },
  methods: {
    getEsaDefaultSettings() {
      this.$store
        .dispatch("getEsaOrdersDefaultOptions")
        .then(() => {
          let data = this.getOrdersAndSettingsOptions;

          if (!data) {
            let errorCode = this.getStatus || "getDefaultOptions";

            this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
            console.log("then getEsaOrdersDefaultOptions error");
          }
        })
        .catch(err => {
          let errorCode = errorHandler(err, "getDefaultOptions");

          this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
          console.log("catch getEsaOrdersDefaultOptions");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    downloadStat(form) {
      if (checkFormDate(form)) {
        this.showSnack("error", [this.$i18n.t("error.WRONG_CHOOSE_DATE")]);

        return;
      }

      const queryUrl = this.createQueryURL(form);

      this.emptyCompanyChart = false;
      this.showDash = false;
      this.form = form;
      if (this.form.companiesOrEmployees === "companies") {
        getEmployeeQuantityStats(this.form.rangeSelected, queryUrl)
          .then(res => {
            this.makeDash(res);
          })
          .catch(err => {
            let errorCode = errorHandler(err, "GET_DATA");

            this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
            this.emptyCompanyChart = true;
          })
          .finally(() => {
            this.showDash = true;
          });
      } else {
        getPBEmployeeQuantityStats(this.form.rangeSelected, queryUrl)
          .then(res => {
            this.makeEmployeeStat(res);
          })
          .catch(err => {
            let errorCode = errorHandler(err, "GET_DATA");

            this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
            this.emptyCompanyChart = true;
          })
          .finally(() => {
            this.showDash = true;
          });
      }
    },
    downloadStatQuantity(form) {
      if (checkFormDate(form)) {
        this.showSnack("error", [this.$i18n.t("error.WRONG_CHOOSE_DATE")]);

        return;
      }

      const queryUrl = this.createQueryURL(form);

      this.loading = true;
      this.formEmployees = form;
      if (this.formEmployees.companiesOrEmployees === "companies") {
        getEmployeeQuantityStats(this.formEmployees.rangeSelected, queryUrl)
          .then(res => {
            this.makeTable(res);
          })
          .catch(err => {
            let errorCode = errorHandler(err, "GET_DATA");

            this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        getPBEmployeeQuantityStats(this.formEmployees.rangeSelected, queryUrl)
          .then(res => {
            this.makeTablePBEmployees(res);
          })
          .catch(err => {
            let errorCode = errorHandler(err, "GET_DATA");

            this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    downloadBySum(form) {
      if (checkFormDate(form)) {
        this.showSnack("error", [this.$i18n.t("error.WRONG_CHOOSE_DATE")]);

        return;
      }

      const queryUrl = this.createQueryURL(form);

      this.emptySumChart = false;
      this.showDashSum = false;
      this.formSum = form;
      if (this.formSum.companiesOrEmployees === "companies") {
        getEmployeeQuantitySumStats(this.formSum.rangeSelected, queryUrl)
          .then(res => {
            this.makeChartSum(res);
          })
          .catch(err => {
            let errorCode = errorHandler(err, "GET_DATA");

            this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
            this.emptySumChart = true;
          })
          .finally(() => {
            this.showDashSum = true;
          });
      } else {
        getPBEmployeeSumStats(this.formSum.rangeSelected, queryUrl)
          .then(res => {
            this.makeChartSum(res);
          })
          .catch(err => {
            let errorCode = errorHandler(err, "GET_DATA");

            this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
            this.emptySumChart = true;
          })
          .finally(() => {
            this.showDashSum = true;
          });
      }
    },
    makeChartSum({ data }) {
      if (this.formSum.companiesOrEmployees === "companies") {
        let companies = [];
        let statisticsOrgDtoList = data?.statisticsOrgDtoList;

        if (this.formSum.company === "all") {
          companies = statisticsOrgDtoList.map(item => {
            item.mapped = this.mappedSumDash(
              item.statisticsTimelapsesDto.mapStatisticsToTimelapse
            );
            return item;
          });
        } else {
          if (statisticsOrgDtoList.length > 0) {
            companies = statisticsOrgDtoList[0].statisticsStoreDto.map(item => {
              item.mapped = this.mappedSumDash(
                item.statisticsTimelapsesDto.mapStatisticsToTimelapse
              );
              return item;
            });
            companies = statisticsOrgDtoList[0].statisticsStoreDto;
          }
        }

        if (!companies.length) {
          this.emptySumChart = true;
          return;
        }

        this.xAxisSum = [...new Set(companies.map(item => item.name))];
        this.createSeriesSum(companies);
        this.showDashSum = true;
      } else {
        const employees = data?.statisticsLdapDtoList.map(item => {
          item.mapped = this.mappedSumDash(
            item.statisticsTimelapsesDto.mapStatisticsToTimelapse
          );

          return item;
        });

        if (!employees.length) {
          this.emptySumChart = true;
          return;
        }

        this.xAxisSum = [...new Set(employees.map(item => item.authorLdap))];
        this.createSeriesSum(employees);
        this.showDashSum = true;
      }
    },
    createSeriesSum(data) {
      this.seriesSum = Object.values(
        Object.values(data).reduce((group, item) => {
          group["givenOnes"] = group["givenOnes"] || {
            name: this.$i18n.t("statistic.totalAmountCPSubmittedToBank"),
            type: "column",
            data: [],
            zIndex: 0,
            color: "#83BF31",
            stack: "givenOnes"
          };
          group["savingOnes"] = group["savingOnes"] || {
            name: this.$i18n.t("statistic.totalAmountWithSavedCP"),
            type: "column",
            data: [],
            zIndex: 0,
            color: "#659525",
            stack: "savingOnes"
          };
          group["creditGivenOnes"] = group["creditGivenOnes"] || {
            name: this.$i18n.t("statistic.totalAmountFundingCPSubmittedToBank"),
            type: "column",
            data: [],
            zIndex: 0,
            color: "#FFD500",
            stack: "creditGivenOnes"
          };
          group["creditSavingOnes"] = group["creditSavingOnes"] || {
            name: this.$i18n.t("statistic.totalAmountFundingWithSavedCP"),
            type: "column",
            data: [],
            zIndex: 0,
            color: "#debf1a",
            stack: "creditSavingOnes"
          };

          group["givenOnes"]["data"].push(Number(item["mapped"]["givenOnes"]));
          group["creditGivenOnes"]["data"].push(
            Number(item["mapped"]["creditGivenOnes"])
          );
          group["creditSavingOnes"]["data"].push(
            Number(item["mapped"]["creditSavingOnes"])
          );
          group["savingOnes"]["data"].push(
            Number(item["mapped"]["savingOnes"])
          );

          return group;
        }, {})
      );
    },
    mappedSumDash(mapStatisticsToTimelapse) {
      let sOnes = 0;
      let cSOnes = 0;
      let gOnes = 0;
      let cGOnes = 0;
      let group = {
        savingOnes: 0,
        creditSavingOnes: 0,
        givenOnes: 0,
        creditGivenOnes: 0
      };

      Object.values(mapStatisticsToTimelapse).forEach(item => {
        sOnes += Number(item["savingOnes"]);
        cSOnes += Number(item["creditSavingOnes"]);
        gOnes += Number(item["givenOnes"]);
        cGOnes += Number(item["creditGivenOnes"]);
      });

      group["savingOnes"] = +sOnes.toFixed(2);
      group["creditSavingOnes"] = +cSOnes.toFixed(2);
      group["givenOnes"] = +gOnes.toFixed(2);
      group["creditGivenOnes"] = +cGOnes.toFixed(2);

      return group;
    },
    setQueryFromAndTo(form, props, type) {
      // реверс дати, якщо першою була вибрана більш пізня дата
      let from = form[`${type}`][0];
      let to = form[`${type}`][1];

      if (props === "from") {
        return from > to ? to : from;
      }
      if (props === "to") {
        return from > to ? from : to;
      }
    },
    createQueryURL(form) {
      const query = {};

      if (form["rangeSelected"] === "date") {
        query["from"] = this.setQueryFromAndTo(form, "from", "dates");
        query["to"] = this.setQueryFromAndTo(form, "to", "dates");
      } else {
        query["from"] = this.setQueryFromAndTo(form, "from", "months");
        query["to"] = this.setQueryFromAndTo(form, "to", "months");
      }
      if (
        form["companiesOrEmployees"] === "companies" &&
        form["company"] !== "all"
      ) {
        query["orgIdSet"] = form["company"];
      }
      if (form["salonSelected"] !== "all") {
        query["storeIdSet"] = form["salonSelected"];
      }

      return new URLSearchParams(query).toString();
    },
    makeDash({ data }) {
      let companies = data?.statisticsOrgDtoList;
      let mapStatisticsToTimelapse = null;

      if (this.form.company !== "all") {
        mapStatisticsToTimelapse =
          companies[0]?.statisticsTimelapsesDto?.mapStatisticsToTimelapse;
      } else {
        mapStatisticsToTimelapse =
          data?.statisticsTimelapsesDto?.mapStatisticsToTimelapse;
      }

      this.createDashStats(mapStatisticsToTimelapse);
      this.emptyCompanyChart = !this.showDash;
    },
    makeEmployeeStat({ data }) {
      let employees = data?.statisticsTimelapsesDto;
      let mapStatisticsToTimelapse = this.createDashStatisticForEmployees(
        employees
      );

      this.createDashStats(mapStatisticsToTimelapse);
      this.emptyCompanyChart = !this.showDash;
    },
    createDashStats(mapStatisticsToTimelapse) {
      if (mapStatisticsToTimelapse) {
        let isDataEmpty = 0;

        this.xAxis = Object.keys(mapStatisticsToTimelapse);

        let grouped = Object.values(mapStatisticsToTimelapse).reduce(
          (group, item) => {
            if (
              this.form.contextSelected === "newOnes" ||
              this.form.contextSelected === "all"
            ) {
              group["newOnes"] = group["newOnes"] || {
                name: this.$i18n.t("statistic.statuses.new"),
                type: "column",
                data: [],
                zIndex: 0,
                color: "#96d6de"
              };
              group["newOnes"]["data"].push(Number(item["newOnes"]));
              isDataEmpty += Number(item["newOnes"]);
            }
            if (
              this.form.contextSelected === "savingOnes" ||
              this.form.contextSelected === "all"
            ) {
              group["savingOnes"] = group["savingOnes"] || {
                name: this.$i18n.t("statistic.statuses.saving"),
                type: "spline",
                data: [],
                zIndex: 1,
                color: "#1d3ce3"
              };
              group["savingOnes"]["data"].push(Number(item["savingOnes"]));
              isDataEmpty += Number(item["newOnes"]);
            }
            if (
              this.form.contextSelected === "givenOnes" ||
              this.form.contextSelected === "all"
            ) {
              group["givenOnes"] = group["givenOnes"] || {
                name: this.$i18n.t("statistic.statuses.given"),
                type: "spline",
                data: [],
                zIndex: 1,
                color: "#bd380b"
              };
              group["givenOnes"]["data"].push(Number(item["givenOnes"]));
              isDataEmpty += Number(item["newOnes"]);
            }
            if (
              this.form.contextSelected === "approvingOnes" ||
              this.form.contextSelected === "all"
            ) {
              group["approvingOnes"] = group["approvingOnes"] || {
                name: this.$i18n.t("statistic.statuses.approving"),
                type: "spline",
                data: [],
                zIndex: 1,
                color: "#FFB300"
              };
              group["approvingOnes"]["data"].push(
                Number(item["approvingOnes"])
              );
              isDataEmpty += Number(item["newOnes"]);
            }
            if (
              this.form.contextSelected === "doneOnes" ||
              this.form.contextSelected === "all"
            ) {
              group["doneOnes"] = group["doneOnes"] || {
                name: this.$i18n.t("statistic.statuses.done"),
                type: "spline",
                data: [],
                zIndex: 1,
                color: "#83BF31"
              };
              group["doneOnes"]["data"].push(Number(item["doneOnes"]));
              isDataEmpty += Number(item["newOnes"]);
            }

            return group;
          },
          {}
        );

        this.yAxisMax = isDataEmpty ? null : 5;
        this.series = Object.values(grouped);
        this.showDash = true;
      }
    },
    createDashStatisticForEmployees(employees) {
      if (employees.mapStatisticsToTimelapse) {
        return employees.mapStatisticsToTimelapse;
      }

      return null;
    },
    makeTable({ data }) {
      let statisticsStoreDto = data?.statisticsOrgDtoList
        .map(item => {
          return item.statisticsStoreDto.flat();
        })
        .flat();

      if (this.formEmployees.salonSelected !== "all") {
        statisticsStoreDto = statisticsStoreDto
          .filter(item => {
            return item.storeId === this.formEmployees.salonSelected;
          })
          .map(item => {
            return item.statisticsEmployeeDto.flat();
          })
          .flat();
      }

      if (statisticsStoreDto.length === 0) {
        this.tableQuantityData = [];
        return;
      }

      if (this.formEmployees.company === "all") {
        this.tableQuantityData = this.createCompaniesTable(
          data.statisticsOrgDtoList
        );
      } else if (this.formEmployees.salonSelected === "all") {
        this.tableQuantityData = createTableQuantity(
          statisticsStoreDto,
          "name"
        );
      } else {
        this.tableQuantityData = createTableQuantity(statisticsStoreDto, "fio");
      }
      this.tableQuantityHeader = this.createHeader();
    },
    makeTablePBEmployees({ data }) {
      let statisticsLdapDtoList = data?.statisticsLdapDtoList
        .map(item => {
          return item;
        })
        .flat();

      if (statisticsLdapDtoList.length === 0) {
        this.tableQuantityData = [];
        return;
      }

      this.tableQuantityData = createTableQuantity(
        statisticsLdapDtoList,
        "authorLdap"
      );
      this.tableQuantityHeader = this.createHeader();
    },
    createHeader() {
      const constHeaders = [
        {
          text: this.$i18n.t("statistic.table.newOwns"),
          value: "newOwns",
          class: "new"
        },
        {
          text: this.$i18n.t("statistic.table.savingOnes"),
          value: "savingOnes",
          class: "saving"
        },
        {
          text: this.$i18n.t("statistic.table.givenOnes"),
          value: "givenOnes",
          class: "given"
        },
        {
          text: this.$i18n.t("statistic.table.approvingOnes"),
          value: "approvingOnes",
          class: "approving"
        },
        {
          text: this.$i18n.t("statistic.table.doneOnes"),
          value: "doneOnes",
          class: "done"
        },
        {
          text: this.$i18n.t("statistic.table.nrmCalling"),
          value: "nrmCalling",
          class: "nrmCalling"
        },
        {
          text: this.$i18n.t("statistic.table.nrmOrStopRejected"),
          value: "nrmOrStopRejected",
          class: "nrmOrStopRejected"
        }
      ];
      let headers = [
        {
          text: this.$i18n.t("statistic.table.company"),
          value: "firstColumn",
          class: "firstColumn"
        }
      ];

      if (this.formEmployees.company !== "all") {
        headers = [
          {
            text: this.$i18n.t("statistic.table.salon"),
            value: "firstColumn",
            class: "firstColumn"
          }
        ];
      }

      if (
        this.formEmployees.companiesOrEmployees === "employees" ||
        this.formEmployees.salonSelected !== "all"
      ) {
        headers = [
          {
            text: this.$i18n.t("statistic.table.employee"),
            value: "firstColumn",
            class: "firstColumn"
          }
        ];
      }
      headers.push(...constHeaders);

      return headers;
    },
    createCompaniesTable(statisticsOrgDtoList) {
      return Object.values(
        statisticsOrgDtoList.reduce((group, item) => {
          group[item["name"]] = group[item["name"]] || {
            firstColumn: item["name"],
            newOwns: 0,
            savingOnes: 0,
            givenOnes: 0,
            approvingOnes: 0,
            doneOnes: 0,
            nrmCalling: 0,
            nrmOrStopRejected: 0
          };

          let counting = item?.statisticsStoreDto;
          let groupItem = group[item["name"]];

          if (counting) {
            Object.values(counting).forEach(count => {
              let counts =
                count.statisticsTimelapsesDto?.mapStatisticsToTimelapse;

              Object.values(counts).forEach(elem => {
                groupItem["newOwns"] += Number(elem["newOnes"]);
                groupItem["savingOnes"] += Number(elem["savingOnes"]);
                groupItem["givenOnes"] += Number(elem["givenOnes"]);
                groupItem["approvingOnes"] += Number(elem["approvingOnes"]);
                groupItem["doneOnes"] += Number(elem["doneOnes"]);
                groupItem["nrmCalling"] += Number(elem["nrmCalling"]);
                groupItem["nrmOrStopRejected"] += Number(
                  elem["nrmOrStopRejected"]
                );
              });
            });
          }

          return group;
        }, {})
      );
    }
  }
};
</script>

<style scoped>
.row-border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}
.progress-wrap {
  position: relative;
  min-height: 100px;
}
</style>
